import React, {useState} from 'react';
import { BiChurch} from 'react-icons/bi';
import { GoThreeBars, GoX } from 'react-icons/go';
import logo from '../../images/logo.png';
import logoSmaller from '../../images/logoSmaller.png';
import './index.css';

import {Nav, 
	NavbarContainer, 
	NavLogo,
	MobileIcon,
	NavLinks,
	NavItem,
	NavMenu,
	NavDropdownItem,
	NavDropdownLinks,
	VerticalLine} from './NavbarElements'

const Navbar = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const menuToggledHandler = () => {
		setMenuOpen((p) => !p);
	}

	
	
	const [colorChange, setColorChange] = useState(false);
	const changeNavbarColor = () => {
		if (window.scrollY >= 800) {
			setColorChange(true);
		}
		else {
			setColorChange(false);
		}
	}
	//window.addEventListener('scroll', changeNavbarColor);

	return (
		<>
			<Nav id='mainNav' className={`${menuOpen ? 'navColor':'navTransparent'} ${colorChange ? 'navAbsolute':'navFixed'}`}>
				<NavbarContainer>
					<NavLogo to='home'>
						<img src={logo} alt="남가주새생명교회" className="logo" />
						<img src={logoSmaller} alt="남가주새생명교회" className="logoSmaller" />
					</NavLogo>
					<MobileIcon>
						{ menuOpen ? <GoX size={40} onClick={menuToggledHandler}/> : <GoThreeBars size={40} onClick={menuToggledHandler}/> }
					</MobileIcon>
					<div className={menuOpen ? "toggledMenu" : "toggledMenuHidden"}>
						<NavDropdownItem>
							<NavDropdownLinks to='home' onClick={menuToggledHandler}>
								<BiChurch/>	
							</NavDropdownLinks>
						</NavDropdownItem>
						
						<NavDropdownItem>
							<NavDropdownLinks to='about' onClick={menuToggledHandler}>안내</NavDropdownLinks>
						</NavDropdownItem>

						<NavDropdownItem>
							<NavDropdownLinks to='services' onClick={menuToggledHandler} offset={-60}>설교</NavDropdownLinks>
						</NavDropdownItem>

						<NavDropdownItem>
							<NavDropdownLinks to='directions' onClick={menuToggledHandler} offset={-60}>광고</NavDropdownLinks>
						</NavDropdownItem>

						<NavDropdownItem>
							<NavDropdownLinks to='news' onClick={menuToggledHandler} offset={-60}>미스바</NavDropdownLinks>	
						</NavDropdownItem>
						
					</div>
					<NavMenu>
						<NavItem>
							<NavLinks to='about'>안내</NavLinks>
						</NavItem>
						<VerticalLine></VerticalLine>
						<NavItem>
							<NavLinks to='services' offset={-60}>설교</NavLinks>
						</NavItem>
						<VerticalLine></VerticalLine>
						<NavItem>
							<NavLinks to='directions' offset={-60}>광고</NavLinks>
						</NavItem>
						<VerticalLine></VerticalLine>
						<NavItem>
							<NavLinks to='news' offset={-60}>미스바</NavLinks>
						</NavItem>
						
					</NavMenu>
				</NavbarContainer>
			</Nav>
		</>
		);
}

export default Navbar;