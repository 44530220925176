import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapContainer = () => {
  
  const mapStyles = {        
    height: "50vh",
    width: "100%"};
  
  const defaultCenter = {
    lat: 33.91980993404113, lng: -117.92875116850527
  }
  
  return (
     <LoadScript
       googleMapsApiKey='AIzaSyCHJ4eVupMJqXYrYa6LLEgsS6NvOUWMZbo'>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={15}
          center={defaultCenter}
        >
            <Marker>
                
            </Marker>
        </GoogleMap>
     </LoadScript>
  )
}

export default MapContainer;