import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'
import "typeface-noto-sans-kr";

export const Nav = styled.nav`
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.25rem;
	width: 100%;
	top: 0;
	z-index: 10;
	transition: 0.3s all ease;
`

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 100px;
	z-index: 1;
	width: 100%;
`

export const NavLogo = styled(LinkS)`
	cursor: pointer;
	display: flex;
	align-items: center;
	padding-top: 1rem;
	padding-left: 3rem;
`

export const MobileIcon = styled.div`
	display: none;

	@media screen and (max-width: 900px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.5rem;
		cursor: pointer;
		color: white;
		padding-top: .5rem;
		padding-right: .2rem;
	}
`

export const NavMenu = styled.div`
	display: flex;
	align-items: center;
	list-styles: none;
	text-align: center;
	padding-right: 4rem;
	padding-top: 1rem;

	@media screen and (max-width: 900px) {
		display: none;
	}
`

export const NavItem = styled.div`
	height: 100px;
`

export const NavLinks = styled(LinkS)`
	color: white;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	font-family: 'adobe-myungjo-std', sans-serif;


	&.active {
		border-bottom: 3px solid #01bf71;
	}
`
export const NavDropdownItem = styled.div`
	text-align: center;
`

export const NavDropdownLinks = styled(LinkS)`
	color: white;
	display: inline-block;
	text-align: center;
	width: 10em;
	align-items: center;
	padding: .2em 0em;
	cursor: pointer;
	font-family: 'adobe-myungjo-std', sans-serif;


	&.active {
		border-bottom: 3px solid #01bf71;
	}
`

export const VerticalLine = styled.div`
	background-color: white;
	margin-left: 1em;
	margin-right: 1em;
	height: 1em;
	width: 1px;
`