import React, {useState} from 'react';
import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router} from 'react-router-dom';
import MapContainer from './components/MapContainer';
import { Chrono } from 'react-chrono';

function App() {

  // Hide Welcome on small resize

	const [smallForWelcome, setSmallForWelcome] = useState(false);
	const resizeForWelcome = () => {
    var offsetHeight = document.getElementById('homeSection').offsetHeight;
		if (offsetHeight < 500) {
      setSmallForWelcome(true);
    }
    else {
      setSmallForWelcome(false);
    }
	}

  window.addEventListener('resize', resizeForWelcome);

  const items = [{
    title: "4월 16일 2008년",
    cardTitle: "Dunkirk",
    cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
    cardDetailedText: "first Force (BEF) wade out to..",  
  },
  {
    title: "1월 1일 2010년",
    cardTitle: "Dunkirk",
    cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
    cardDetailedText: "secondh Expeditionary Force (BEF) wade out to..",  
  },
  {
    title: "1월 15일 2020년",
    cardTitle: "Dunkirk",
    cardSubtitle:"Men of the British Expeditionary Force (BEF) wade out to..",
    cardDetailedText: "Mthird Expeditionary Force (BEF) wade out to..",  
  }
  ];

  return (
    <main id='home'>
      <Router>
        <Navbar/>

        <section className='home' id="home">
          <div id='homeSection' className='sectionContainer'>
            <div class='sectionContent'>
              <div class='mainPageCenterContentContainer'>
                <div class='mainPageCenterContentLabel'>
                  [2022년 표어]
                </div>
                <div class='mainPageCenterContentText'>
                  <div class='textInWhite'>
                    더 깊은 사귐과 동행
                  </div>
                  <div class='textInColor'>
                    (feat. 성령)
                  </div>
                </div>
              </div>
              <div class='welcomeContainer'>
                <div class={smallForWelcome ? 'welcomeButtonNoShow' : 'welcomeButton'}>
                  WELCOME
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='about' id='about'>
          <div id="aboutSection" className="sectionContainer">
            <div class="sectionContent">
              <div class='aboutPageCenterContentContainer'>
                <div class='aboutPageSpacer'></div>
                <div class='aboutPageCenterContentText'>
                  <div class='aboutText aboutWhiteText aboutText1 blockText'>
                    남가주새생명교회는
                  </div>
                  <div class='aboutText aboutColorText aboutText2 blockText'>
                    기적이 삶이 되는,
                  </div>
                  <div class='aboutText aboutColorText aboutText3 blockText'>
                    말씀이 중심이 되는,
                  </div>
                  <div class='aboutText aboutText4 blockText'>
                    <div class='aboutText aboutColorText aboutText41'>
                      변화를 두려워하지 않는 교회
                    </div>
                    <div class='aboutText aboutWhiteText aboutText42'>
                      로
                    </div>
                  </div>
                  <div class='aboutText aboutWhiteText aboutText5 blockText'>
                    나아갈 것입니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='aboutInfo' id='aboutInfo'>
          <div id="aboutInfoSection" className="sectionContainer">
            <div className="sectionContent">
              <div className="peopleSection">
                <div className="labelBox">
                  <div className="labelText">
                  섬기는 분들
                  </div>
                </div>
                <div className="lineSectionContent">
                  <div className="lineItem">
                    담임목사 : 박형준
                  </div>
                  <div className="lineItem">
                    전도사 : 추지훈
                  </div>
                </div>
              </div>

              <div className="historySection sectionPadding">
                <div className="labelBox">
                  <div className="labelText">
                  예배시간
                  </div>
                </div>
                <div className="lineSectionContent">
                  <div className="lineItem">
                    주일1부 오전9시 , 2부 오전 11시
                  </div>
                  <div className="lineItem">
                    어린이 교회학교 오전 11시
                  </div>
                  <div className="lineItem">
                    수요예배 오후 7:30
                  </div>
                  <div className="lineItem">
                    금요기도회 오후 9시
                  </div>
                  <div className="lineItem">
                    새벽기도회 월~금 오전 5:30
                  </div>
                </div>
              </div>
              <div className="servicesSection">

              </div>
              <div className="directionsSection sectionPadding">
                <div className="labelBox">
                  <div className="labelText">
                  오시는 길
                  </div>
                </div>
                <div className="lineSectionContent">
                  <div className="lineItem address">
                    261 E Imperial Hwy #550, Fullerton, CA 92835
                  </div>

                  <div className="address googleMaps">
                    <iframe width="360" height="360" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJQX2QBOEr3YARaULuIcAPnSg&key=AIzaSyCHJ4eVupMJqXYrYa6LLEgsS6NvOUWMZbo&zoom=13"></iframe>
                  </div>

                  <div className="lineItem address">
                    <div className="callMe">
                      문의전화
                    </div>

                    <div className="phoneNumber">
                      714)323-1194
                    </div>
                  </div>

                </div>
                
              </div>
            </div>
          </div>
        </section>

        
      </Router>
    </main>
  );
}

export default App;
